import React from "react"
import "./style.css"

const facebookIcon = require("../../images/facebook-icon.png")

export default () => {
  return (
    <footer>
      <h1>Kontakt & Öppettider</h1>
      <ul>
        <li>
          <div className="left">Telefon</div>-
          <div className="right"> 0705 25 45</div>
        </li>
        <li>
          <div className="left">Besöksaddress</div>-
          <div className="right"> Östra Strö 1609 214 91, Östa Strö</div>
        </li>
        <li>
          <div className="left">Öppettider</div>-
          <div className="right"> 10 till 18</div>
        </li>
      </ul>
      <a href="https://www.facebook.com/gladagrisar/">
        <img src={facebookIcon} />
      </a>
      <p>Följ oss på Facebook för nyheter och uppdateringar.</p>
    </footer>
  )
}
